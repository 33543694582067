import React from 'react'
import get from 'lodash/get'
import tw, { styled } from 'twin.macro'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import SmallCase from '../components/case-small'

const Hero = styled.div`
  ${tw`w-full xl:w-3/4 mt-12 mb-10 md:my-24 flex items-center`};
`
const Title = styled.h1`
  ${tw`text-black dark:text-white font-normal text-4xl md:text-5xl`};
`
const Subtitle = styled.span`
  ${tw`text-lg`};
`
const Section = styled.section`
  ${tw``};
`
const Articles = styled.div`
  ${tw`flex flex-wrap my-4`};
`
class PortfolioPage extends React.Component {
  render() {
    const cases = get(this, 'props.data.allContentfulPortfolioEntry.edges')
    return (
      <>
        <SEO
          title="Projects | James Does Digital"
          description="Find out more about some of the projects and companies that James Does Digital has been involved in over the last few years."
          keywords={[`cloud`, `projects`, `aws`, `react`, `graphql`]}
        />
        <Hero>
          <div>
            <Title>
              A selection of some of the projects I've been involved in over the
              last few years.
            </Title>
            <Subtitle>
              For more details on the kind of things I've been up to on a more
              technical level, feel free to take a look at some of my{' '}
              <Link to="/articles">articles</Link>.
            </Subtitle>
          </div>
        </Hero>
        <Section>
          <Articles>
            {cases.map(({ node }) => {
              return <SmallCase entry={node} key={node.slug} />
            })}
          </Articles>
        </Section>
      </>
    )
  }
}

export default PortfolioPage

export const pageQuery = graphql`
  query PortfolioQuery {
    allContentfulPortfolioEntry(
      filter: { draft: { ne: true } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 20
    ) {
      edges {
        node {
          title
          clientName
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          description
          listImage {
            gatsbyImageData(
              quality: 90
              width: 800
              height: 400
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`
